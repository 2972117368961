
const MeetOurTeamClan = [
    {
        id: 1,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Ishika D.webp'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Ishika.webp'),
        clanHeading: "Ishika D",
        clanParaFront: "Human Resource Executive",
        clanParaBack: "Wanted to be an Animator"
    },
    {
        id: 2,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Renu-S.jpeg'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Renu.png'),
        clanHeading: "Renu S",
        clanParaFront: "Operation's Head",
        clanParaBack: "Wanted to be an Interior Designer"
    },
    {
        id: 3,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Punith-R.jpeg'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Punith.png'),
        clanHeading: "Punith R A",
        clanParaFront: "Team Lead",
        clanParaBack: "Wanted to be an Aerospace Engineer"
    },
    {
        id: 4,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Shailaja-B.webp'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Shailaja.webp'),
        clanHeading: "Shailaja B",
        clanParaFront: "Digital Marketing Executive",
        clanParaBack: "Wanted to be a Doctor"
    },
    {
        id: 5,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Faisal-K.jpeg'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Faisal.png'),
        clanHeading: "Faisal J Khan",
        clanParaFront: "Front-end Developer",
        clanParaBack: "Wanted to be an Astronaut"
    },
    // {
    //     id: 6,
    //     clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Gourish-P.webp'),
    //     clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Gourish.webp'),
    //     clanHeading: "Gourish P",
    //     clanParaFront: "UI/UX Designer",
    //     clanParaBack: "Wanted to be a Travel Photographer"
    // },
    {
        id: 6,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Sharmista-S.webp'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Sharmista.webp'),
        clanHeading: "Sharmista S",
        clanParaFront: "Data Entry Operator",
        clanParaBack: "Wanted to be an Nursery Teacher"
    },
    {
        id: 7,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Gowthami-N.jpeg'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Gowthami.png'),
        clanHeading: "Gowthami N",
        clanParaFront: "Content Writer",
        clanParaBack: "Wanted to be a Doctor"
    },
    {
        id: 8,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Swarnali-B.webp'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Swarnali.webp'),
        clanHeading: "Swarnali B",
        clanParaFront: "Content Writer",
        clanParaBack: "Wanted to be an Athlete"
    },
    {
        id: 9,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Susmita-S.webp'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Susmita.webp'),
        clanHeading: "Susmita S",
        clanParaFront: "Content Writer",
        clanParaBack: "Wanted to be a Thespian"
    },
    // {
    //     id: 9,
    //     clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Ishika D.webp'),
    //     clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Ishika.webp'),
    //     clanHeading: "Ishika D",
    //     clanParaFront: "Content Writer",
    //     clanParaBack: "Wanted to be an Animator"
    // },
    {
        id: 10,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Priyankesh M.webp'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Priyankesh.webp'),
        clanHeading: "Priyankesh M",
        clanParaFront: "Content Writer",
        clanParaBack: "Wanted to be a Microbiologist"
    },
];

export default MeetOurTeamClan;