import { NavLink } from "react-router-dom";
import BannerImg2 from "../../../Assets/Events/2022/Banner_Images/banner_15.png";
import BannerImg4 from "../../../Assets/Events/2025/Banner_Images/Holi_Banner.webp";
import BannerImg1 from "../../../Assets/Events/2025/Banner_Images/Republic_Day_Banner.webp";
import BannerImg3 from "../../../Assets/Events/2025/Banner_Images/Womens_Day_Banner.webp";


const Events2025Banner = [
    {
        id: 1,
        bannerImage: <NavLink to={"/republic-day-2025"} className="href"><img src={BannerImg1} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/republic-day-2025"} className="href">Republic Day #2025</NavLink></h4>
    },
    {
        id: 2,
        bannerImage: <NavLink to={"/womens-day-2025"} className="href"><img src={BannerImg3} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/womens-day-2025"} className="href">Womes's Day #2025</NavLink></h4>
    },
    {
        id: 2,
        bannerImage: <NavLink to={"/happy-holi-2025"} className="href"><img src={BannerImg4} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/happy-holi-2025"} className="href">Happy Holi #2025</NavLink></h4>
    },
    {
        id: 3,
        bannerImage: <NavLink to={"/birthday-celebration-2025"} className="href"><img src={BannerImg2} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/birthday-celebration-2025"} className="href">Birthday Celebrations</NavLink></h4>
    },
    // {
    //     id: 3,
    //     bannerImage: <NavLink to={"/diwali-2024"} className="href"><img src={BannerImg5} alt="" className='img-fluid' /></NavLink>,
    //     bannerPara: <h4><NavLink to={"/diwali-2024"} className="href">Diwali #2024</NavLink></h4>
    // },
    // {
    //     id: 3,
    //     bannerImage: <NavLink to={"/christmas-2024"} className="href"><img src={BannerImg4} alt="" className='img-fluid' /></NavLink>,
    //     bannerPara: <h4><NavLink to={"/christmas-2024"} className="href">Christmas #2024</NavLink></h4>
    // },
    // {
    //     id: 4,
    //     bannerImage: <NavLink to={"/birthday-celebration-2024"} className="href"><img src={BannerImg2} alt="" className='img-fluid' /></NavLink>,
    //     bannerPara: <h4><NavLink to={"/birthday-celebration-2024"} className="href">Birthday Celebrations</NavLink></h4>
    // },
];

export default Events2025Banner;