import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2025/Republic_Day_2025/Republic_Day_2025_1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2022/Republic-Day-2022/8.3.png";
import RDayImage3 from "../../../../../../Assets/Events/2024/Republic_Day_2024/Republic_Day_24_1.webp";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const RepublicDay2025 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Celebrating 76<sup>th</sup> Republic Day</h1>
                            <p>On 26th January 2025, Holistic Learning proudly celebrated the <b style={{color: "rgba(187, 61, 39, 0.95)"}}>76th Republic Day</b> with immense enthusiasm and patriotism. The day was filled with joy, unity, and a deep sense of national pride as our team came together to honour this significant occasion.</p>
                            <p>The event was meticulously organized by Punith and Shailaja, who ensured that every aspect of the celebration was engaging and memorable for all team members.</p>
                            <p>To mark the celebration, all team members dressed in beautiful ethnic attire, representing India's rich cultural heritage. The vibrant colors of our traditional outfits reflected the diversity and unity that define our great nation. It was heart-warming to see everyone embracing the spirit of the day, making it a memorable experience for all.</p>
                            <p>Our office was adorned with tricolor balloons and the Indian flag, creating a festive and patriotic atmosphere. The decorations added to the excitement, reminding us of the sacrifices made by our freedom fighters and the values that our Constitution upholds. The sight of the national flag fluttering proudly in our workspace filled everyone with a sense of gratitude and responsibility.</p>
                            <p>The highlight of the celebration was the engaging games and quizzes organized for the team. We played an exciting Republic Day Quiz, testing our knowledge of India's history, constitution, and great leaders. It was a fun yet educational experience, allowing everyone to learn more about the country’s journey towards becoming a sovereign republic. Friendly competition and teamwork made the quiz even more enjoyable, with participants eagerly answering questions and sharing interesting facts.</p>
                            <p>Apart from the quiz, we played several team-building games that strengthened our bond and enhanced collaboration among colleagues. These activities not only added an element of fun to the celebration but also emphasized the importance of unity and working together—values that are essential both in a professional environment and as responsible citizens of India.</p>
                            <p>No celebration is complete without delicious food, and Republic Day at Holistic Learning was no exception! We all gathered for a special lunch, enjoying a variety of delicious Indian dishes. Sharing a meal together created a sense of togetherness, allowing us to bond over stories, laughter, and our love for the diverse flavours  of Indian cuisine. The food was a delightful treat, adding warmth and joy to the celebration.</p>
                            <p>As the day came to an end, we reflected on the significance of Republic Day and what it means to each of us. It was a reminder of the values of democracy, equality, and freedom that we are privileged to enjoy. The celebration at Holistic Learning was not just about games and decorations; it was about cherishing the spirit of India and acknowledging our collective responsibility to contribute to its progress.</p>
                            <p>The <b style={{color: "rgba(187, 61, 39, 0.95)"}}>76th Republic Day</b> celebration at Holistic Learning was truly special, leaving us all with cherished memories and a renewed sense of pride in our country. As we move forward, we remain committed to upholding the ideals of unity, diversity, and excellence in everything we do. Jai Hind! 🇮🇳</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Shailaja V B</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href">
                                <button>
                                    <span className="icon">
                                        <FaArrowLeft />
                                    </span>{" "}
                                    View All Events
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RepublicDay2025;
