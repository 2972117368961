import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2025/Womens-Day-2025/Womens_Day_2025_1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2025/Womens-Day-2025/Womens_Day_2025_2.webp";
import RDayImage3 from "../../../../../../Assets/Events/2025/Womens-Day-2025/Womens_Day_2025_3.webp";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const WomensDay2025 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Celebrating Women’s Day at Holistic Learning</h1>
                            <p>On March 8, Holistic Learning came up with the idea of a vibrant celebration of <b style={{color: "rgba(187, 61, 39, 0.95)"}}>International Women’s Day</b>. We chose to celebrate all the incredible women who enrich our lives and workplaces.  The day was filled with so much positivity, warmth, happiness and gratitude for their contributions.</p>
                            <p>We started off the day by a joyful cake-cutting ceremony, where everyone was in their happiest self, the room was filled with happiness and everyone joined the celebration to share this sweet moment. The cake, adorned with beautiful decorations, symbolizing just not a mere treat but also, it’s the unity and strength of our community. There was an arrangement of sumptuous feast post the cake cutting which brought everyone together around the table.  Each of them shared different stories as well as laughter over delicious dishes. </p>
                            <p>Throughout the entire day, we spent reflecting on the matter of how important it is to support each other. The entire conversation was discussing about empowerment, resilience, and the unique journeys each woman has taken in their life. It was a reminder about any achievement big or small, it deserves recognition and celebration.</p>
                            <p>As the day appeared closer, we all were left with both gratitude and inspiration. We are committed to represent an environment where women are valued and encouraged to pursue their dreams.</p>
                            <p>In the spirit of women’s day celebration, let’s move ahead with the wonderful words stated by Michelle Obama: “There is no limit to what we, as women, can accomplish.” This quote deeply resonates as we are continuing to uplift and empower one another every day.</p>
                            <p>At Holistic Learning, we believe in celebrating women not just on Women's Day but every day.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Sushmita S</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href">
                                <button>
                                    <span className="icon">
                                        <FaArrowLeft />
                                    </span>{" "}
                                    View All Events
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WomensDay2025;
